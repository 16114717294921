import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         Test Page
        </p>
       This is my Survey
      </header>
    </div>

  );
}

export default App;
